<template>
  <div class="feed-back main-wrap">
    <search-form @search="list" @open="open" />
    <list-table :list="data" @edit="edit" @del="del" @sort="sortData" />
    <add-form :show="show" @open="open" @add="add" />
  </div>
</template>

<script>
import searchForm from './components/search-form'
import listTable from './components/list-table'
import addForm from './components/add-form'
import service from './api'
import pagination from '@/mixins/pagination'
export default {
  mixins: [pagination],
  data () {
    return {
      data: [],
      show: false
    }
  },
  components: {
    searchForm,
    listTable,
    addForm
  },
  methods: {
    list (params) {
      return service.list(params).then(res => {
        this.data = res
        // 金额 分转元
        this.data.map(it => {
          it.amount = it.amount / 100
        })
      })
    },
    edit (rows) {
      // 金额 元转分
      let params = rows.map(it => {
        let temp = { ...it }
        temp.amount = temp.amount * 100
        return temp
      })
      return service.editBatch(params).then(() => {
        this.$notify({ type: 'success', message: '操作成功！' })
      })
    },
    add (param) {
      // 金额 元转分
      param.amount = param.amount * 100
      return service.add(param).then(() => {
        this.$notify({ type: 'success', message: '操作成功！' })
        this.open(false)
        this.list()
      })
    },
    del (id) {
      return service.del(id).then(() => {
        this.$notify({ type: 'success', message: '操作成功！' })
        this.list()
      })
    },
    sortData () {
      this.data.sort((a, b) => a.sort - b.sort)
    },
    open (show) {
      this.show = show
    }
  },
  mounted () {
    this.list()
  }
};
</script>

<style lang="scss" scoped></style>
