import API from "@/api";

export default {
  // 获取列表
  list: (params) => {
    return API.requestPost({
      url: "reportProps/list",
      data: params,
    });
  },
  // 编辑
  editBatch: (params) => {
    return API.post({
      url: "reportProps/editBatch",
      data: params,
    });
  },
  // 新增
  add: (param) => {
    return API.post({
      url: "reportProps/add",
      data: param,
    });
  },
  // 删除
  del: (id) => {
    return API.post({
      url: "reportProps/del",
      data: { id },
    });
  },
};
