<template>
  <el-drawer title="新增报告类型" :visible.sync="show" direction="rtl" :before-close="handleClose">
    <el-form :model="form" :rules="rules" label-width="100px" class="form">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="编码" prop="code">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item label="金额" prop="amount">
        <el-input-number v-model="form.amount" :min="1" :max="10" label="金额"></el-input-number>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="0"
          :inactive-value="1">
        </el-switch>
      </el-form-item>
      <el-form-item label="模式" prop="status">
        <el-radio v-model="form.mode" :label="0">预览</el-radio>
        <el-radio v-model="form.mode" :label="1">接口</el-radio>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="$emit('add', form)">保存</el-button>
      <el-button @click="$emit('open', false)">取消</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    show: {
      tyep: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      defaultForm: { amount: 0, status: 0, mode: 0 },
      form: {},
      rules: {
        name: [{ required: true, message: '请填写名称', trigger: 'blur' }],
        code: [{ required: true, message: '请填写编码', trigger: 'blur' }],
        amount: [{ required: true, message: '请填写金额', trigger: 'blur' }]
      }
    }
  },
  created(){
    this.form = {...this.defaultForm}
  },
  methods: {
    handleClose () {
      this.form = { ...this.defaultForm }
      this.$emit('open', false)
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  padding: 10px;
  height: 90%;
}

.el-form-item {
  width: 90%;
}

.footer {
  text-align: center;
  margin-bottom: 10px;
}
</style>