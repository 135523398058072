<template>
  <div class="feed table-wrap">
    <el-table :data="list" border>
      <el-table-column v-for="item in columns" :key="item.prop" :prop="item.prop" :label="item.label" align="center"
        :width="item.width">
        <template slot-scope="scope">
          <el-switch v-if="item.prop == 'status'" v-model="scope.row[item.prop]" active-color="#13ce66" active-text="启用" inactive-text="禁用"
            inactive-color="#ff4949" :active-value="0" @change="eidt([scope.row])" :inactive-value="1">
          </el-switch>
          <el-switch v-else-if="item.prop == 'mode'" v-model="scope.row[item.prop]" active-text="接口" inactive-text="预览"
            :active-value="0" @change="eidt([scope.row])" :inactive-value="1">
          </el-switch>
          <el-input-number v-else-if="item.prop == 'amount' && scope.row.editable && !item.disable"
            v-model="scope.row[item.prop]" :min="1" :max="10" label="描述文字"></el-input-number>
          <el-input v-else-if="scope.row.editable && !item.disable" v-model="scope.row[item.prop]" size="small" />
          <span v-else>{{ item.prop == 'index' ? scope.$index + 1 : scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <!-- 其他列定义 -->
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-button round :disabled="scope.$index == list.length - 1" @click="move(scope.$index, -1)" size="small"><i
                class="el-icon-sort-down"></i></el-button>
            <el-button round :disabled="scope.$index == 0" @click="move(scope.$index, 1)" size="small"><i
                class="el-icon-sort-up"></i></el-button>
            <el-button round @click="opeanEdit(scope.row)" size="small"><i
                :class="scope.row.editable ? 'el-icon-check' : 'el-icon-edit'"></i></el-button>
            <el-button round @click="del(scope.row.id)" size="small"><i class="el-icon-delete"></i></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";

export default {
  props: {
    list: {
      tyep: Array,
      default: () => []
    }
  },
  data () {
    return {
      columns: [
        { prop: 'index', label: '序号', width: 65, disable: true },
        { prop: 'name', label: '名称' },
        { prop: 'code', label: '编码' },
        { prop: 'amount', label: '金额（元）' },
        { prop: 'mode', label: '模式' },
        { prop: 'status', label: '状态' }]
    }
  },
  methods: {
    move (index, reduce) {
      // 上移自身加 原上级的减
      // 下移自身减 原下级的加
      let row = this.list[index - reduce]
      let moveRow = this.list[index]
      row.sort = row.sort + reduce
      moveRow.sort = moveRow.sort - reduce
      this.eidt([row, moveRow])
      this.$emit('sort')
    },
    async del (id) {
      // 提示确认信息
      const confirm = await MessageBox.alert(
        `本操作不可还原，确定删除吗？`,
        "警告",
        { showCancelButton: true }
      )
        .then(() => true)
        .catch(() => false);
      if (confirm) {
        this.$emit('del', id)
      }
    },
    opeanEdit (row) {
      this.$set(row, 'editable', !row.editable)
      if (!row.editable) { // 保存
        this.eidt([row])
      }
    },
    eidt (rows) {
      this.$emit('edit', rows)
    }
  }
};
</script>

<style lang="scss" scoped>
.el-icon-sort-down {
  color: #409EFF;
}

.el-icon-sort-up {
  color: #409EFF;
}

.el-icon-delete {
  color: red;
}

.el-icon-edit {
  color: #409EFF;
}

.el-icon-s-claim {
  color: #0de170;
}
</style>
